import React, { useEffect, useState } from 'react'
import { createBlog } from '_services/blog'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CardBody from 'components/Card/CardBody';
import Swal from 'sweetalert2';
import CustomInput from 'components/CustomInput/CustomInput';
import { createAgreement } from '_services/agreement';
import { updateAgreement } from '_services/agreement';

const Agreement = props => {
    const [user] = useAtom(userAtom)
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [_html, _setHtml] = useState(EditorState.createEmpty())
    const [ agreements, setAgreements ] = useState([])
    const [ agreementIndex, setAgreementIndex ] = useState(0)
    const [id, setId] = useState("")
    const [mode, setMode] = useState(0)

    const { location } = props
    const { token } = user



    useEffect(() => {
        if (location && location.agreements ) {
            setAgreements(location.agreements)
            setAgreementIndex(location.agreementIndex || 0)
        }
        return () => {
            setId('')
            setMode(0)
            setTitle('')
            setLink('')
            _setHtml(EditorState.createEmpty())
        }
    }, [location])

    useEffect(() => {
        if(agreements.length > 0){
            const agreement = agreements[agreementIndex]
            setId(agreement._id || '')
            setMode(1)
            setTitle(agreement.title || '')
            setLink(agreement.link || '')
            const blocksFromHtml = htmlToDraft(agreement._html || "<p></p>");
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            _setHtml(editorState)
        }
    },[agreementIndex, agreements])

    const handleSubmit = () => {
        let data = { title, link, _html: draftToHtml(convertToRaw(_html.getCurrentContent())) }
        createAgreement(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Başarısız."
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Başarısız!"
                })
            })
    }

    const handleEditSubmit = () => {
        let data = { id, title, link, _html: draftToHtml(convertToRaw(_html.getCurrentContent())) }
        updateAgreement(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Başarısız."
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Başarısız!"
                })
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Sozleşme Detay</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={ mode === 0 ? handleSubmit : handleEditSubmit}> Sozleşme {mode === 0 ? "Ekle" : "Düzenle"} </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Sozleşme Başlığı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: title,
                                        onChange: (e) => setTitle(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Sozleşme Url'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: link,
                                        onChange: (e) => setLink(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <div style={{ minHeight: 200, marginTop: 30 }}>
                                    <Editor
                                        placeholder="Sözleşme Metnini Giriniz."
                                        toolbarOnFocus
                                        editorState={_html}
                                        toolbarClassName='toolbar'
                                        wrapperClassName='wrapper'
                                        editorClassName='editor'
                                        onEditorStateChange={(editorState) => _setHtml(editorState)}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                                            inline: { options: ['bold', 'italic', 'underline'] }
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Agreement