import axios from 'axios';
import { domain } from './domain';

export const getCoaches = (token) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
    };
    return axios(domain + '/getcoaches', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const coachRegister = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/coachregister', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const coachUpdate = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/coachupdate', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

