import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import DataGrid from "components/DataGrid/SimpleGrid"
import Button from 'components/CustomButtons/Button'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import FoodDialog from "./FoodDialog"
import { getFoods } from "_services/food"
import { foodTypes } from "variables/foodVariables"
import { useAtom } from "jotai"
import { foodAtom } from "_atoms/foodAtom"

const styles = {

}

const useStyles = makeStyles(styles)

const NutritionTable = props => {
    const [ fatom, setFatom ] = useAtom(foodAtom)
    const [ foods, setFoods] = useState([])
    const [ food, setFood ] = useState({})
    const [ foodIndex, setFoodIndex ] = useState("")
    const [ foodDialog, setFoodDialog ] = useState(false)
    const [ foodType, setFoodType ] = useState(foodTypes[0] || '')

    const classes = useStyles()

    const foodDialogToggle = () => {
        setFoodDialog(!foodDialog)
        if(foodDialog){
            setFood({})
            setFoodIndex("")
        }
    }

    const addLocalFood = food => {
        setFoods([
            ...foods,
            food
        ])
    }

    useEffect(() => {
        if ( fatom.length === 0 ) {
            getFoods({})
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setFatom(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },[fatom])

    useEffect(() => {
        const foods = fatom.filter( f => f.group === foodType )
        setFoods(foods)
    },[foodType, fatom])

    const updateLocalFood = (f) => {
        setFoods([
            ...foods.slice(0, foodIndex),
            f,
            ...foods.slice(foodIndex + 1)
        ])
    }

    const columns = [
        { name: 'name', title: "Besin Adı" },
        { name: 'group', title: "Grubu" },
        { name: 'unit', title: "Ölçü" },
        { name: 'measure', title: "Birim" },
        { name: 'calori', title: "Kalori" },
        { name: 'crh', title: "Krh." },
        { name: 'protein', title: "Protein" },
        { name: 'fat', title: "Yağ" },
        { name: 'action', title: "İşlem" }
    ];

    const rows = () => {
        return foods.map((p,i) => ({
            name: p.name || '',
            group: p.group || '',
            unit: p.unit || '',
            measure: p.measure || '',
            calori: p.calori || "",
            crh: p.crh || "",
            protein: p.protein || "",
            fat: p.fat || "",
            action: <Tooltip title='Düzenle'>
                    <IconButton color='primary' onClick = { () => {
                        setFood(p)
                        setFoodIndex(i)
                        foodDialogToggle()
                    }} >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 200 },
        { columnName: 'group', width: 100 },
        { columnName: 'unit', width: 100 },
        { columnName: 'measure', width: 100 },
        { columnName: 'calori', width: 100 },
        { columnName: 'crh', width: 100 },
        { columnName: 'protein', width: 100 },
        { columnName: 'fat', width: 100 },
        { columnName: 'action', width: 100 }
    ];

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={10}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}> Besinler </h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={2}>
                                <Button color='success' block onClick={foodDialogToggle}>Ekle</Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        { foodTypes.map( m => {
                            return(
                                <Button color={foodType === m ? 'success' : "info"} key={m} onClick={() => setFoodType(m)} style={{marginRight : 10, marginBottom : 5}} >{m}</Button>
                            )
                        })}
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <FoodDialog
                open = { foodDialog }
                toggle = { foodDialogToggle }
                addLocalFood = { addLocalFood }
                food = { food }
                updateLocalFood = {updateLocalFood}
            />
        </GridContainer>
    )
}

export default NutritionTable