import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useAtom } from 'jotai'
import { userAtom } from '_atoms/user'
import { createFood } from '_services/food'
import Swal from 'sweetalert2'
import { updateFood } from '_services/food'
import { foodTypes, measures } from 'variables/foodVariables'

const useStyles = makeStyles(styles)

const FoodDialog = props => {
    const [user] = useAtom(userAtom)
    const { open, toggle, addLocalFood, food, updateLocalFood } = props
    const [name, setName] = useState("")
    const [group, setGroup] = useState("")
    const [unit, setUnit] = useState("")
    const [measure, setMeasure] = useState("")
    const [calori, setCalori] = useState("")
    const [crh, setCrh] = useState("")
    const [protein, setProtein] = useState("")
    const [fat, setFat] = useState("")
    const [id, setId] = useState("")

    const { token } = user

    const classes = useStyles()

    const handleClose = () => {
        setName("")
        setGroup("")
        setUnit("")
        setMeasure("")
        setCalori("")
        setCrh("")
        setProtein("")
        setFat("")
        setId("")
        toggle()
    }

    useEffect(() => {
        if (food._id) {
            setId(food._id || '')
            setName(food.name || '')
            setGroup(food.group || '')
            setUnit(food.unit || '')
            setMeasure(food.measure || '')
            setCalori(food.calori || '')
            setCrh(food.crh || '')
            setProtein(food.protein || '')
            setFat(food.fat || '')
        }
    }, [food])

    const handleSubmit = () => {
        const data = { name, group, unit, measure, calori, crh, protein, fat }
        createFood(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    addLocalFood(res.data.data)
                    handleClose()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
            })
    }

    const handleEdit = () => {
        const data = { id, name, group, unit, measure, calori, crh, protein, fat }
        updateFood(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    updateLocalFood(res.data.data)
                    handleClose()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md' style={{ zIndex: 2 }}>
            <DialogTitle>
                Besin Ekle
            </DialogTitle>
            <DialogContent>
                <GridContainer alignItems='center'>
                    <GridItem xs={8}>
                        <CustomInput
                            labelText='Besin Adı'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: name,
                                onChange: (e) => setName(e.target.value),
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="multiple-select"
                                className={classes.selectLabel}
                            >
                                Grubu
                            </InputLabel>
                            <Select
                                value={group}
                                onChange={e => setGroup(e.target.value)}
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                inputProps={{
                                    name: "multipleSelect",
                                    id: "multiple-select"
                                }}
                                style={{ marginBottom: 10 }}
                            >
                                <MenuItem
                                    value=''
                                    disabled
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    Seçiniz
                                </MenuItem>
                                {foodTypes.map(ft => {
                                    return (
                                        <MenuItem
                                            key = {ft}
                                            value={ft}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            {ft}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <CustomInput
                            labelText='Birim Ölçü'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: unit,
                                onChange: (e) => setUnit(e.target.value),
                                type: 'number'
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="multiple-select"
                                className={classes.selectLabel}
                            >
                                Ölçü
                            </InputLabel>
                            <Select
                                value={measure}
                                onChange={e => setMeasure(e.target.value)}
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                inputProps={{
                                    name: "multipleSelect",
                                    id: "multiple-select"
                                }}
                                style={{ marginBottom: 10 }}
                            >
                                <MenuItem
                                    value=''
                                    disabled
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    Seçiniz
                                </MenuItem>
                                {measures.map(m => {
                                    return (
                                        <MenuItem
                                            key={m}
                                            value={m}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            {m}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <CustomInput
                            labelText='Kalori'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: calori,
                                onChange: (e) => setCalori(e.target.value),
                                type: "number"
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <CustomInput
                            labelText='Karbonhidrat'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: crh,
                                onChange: (e) => setCrh(e.target.value),
                                type: "number"
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <CustomInput
                            labelText='Protein'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: protein,
                                onChange: (e) => setProtein(e.target.value),
                                type: "number"
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} md={4}>
                        <CustomInput
                            labelText='Yağ'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: fat,
                                onChange: (e) => setFat(e.target.value),
                                type: "number"
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button color='danger' onClick={handleClose}>Kapat</Button>
                {id === ''
                    ? <Button color='success' onClick={handleSubmit}>Ekle</Button>
                    : <Button color='success' onClick={handleEdit}>Düzenle</Button>
                }

            </DialogActions>
        </Dialog>
    )
}

export default FoodDialog