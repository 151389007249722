import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getFoods } from "_services/food"
import DataGrid from 'components/DataGrid/SimpleGrid';
import AddIcon from '@material-ui/icons/Add';
import { foodTypes } from 'variables/foodVariables';
import Button from 'components/CustomButtons/Button'
import { useAtom } from 'jotai';
import { foodAtom } from '_atoms/foodAtom';

const styles = {

}

const useStyles = makeStyles((theme) => ({
    ...styles,
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FoodSelectDialog = props => {
    const [ fatom, setFatom ] = useAtom(foodAtom)
    const [ foods, setFoods ] = useState([])
    const { open, toggle, handleAction, foodIndex, meal, isNew } = props
    const [ foodType, setFoodType ] = useState(foodTypes[0] || '')
    const classes = useStyles();

    const handleClose = props => {
        toggle()
    }

    useEffect(() => {
        if (open && fatom.length === 0) {
            getFoods({})
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setFatom(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [ open ])

    useEffect(() => {
        const foods = fatom.filter( f => f.group === foodType )
        setFoods(foods)
    },[foodType, fatom])

    const columns = [
        { name: 'action', title: "İşlem" },
        { name: 'name', title: "Besin Adı" },
        { name: 'group', title: "Grubu" },
        { name: 'unit', title: "Birim Ölçü" },
        { name: 'measure', title: "Ölçü" },
        { name: 'calori', title: "Kalori" },
        { name: 'crh', title: "Karbonhidrat" },
        { name: 'protein', title: "Protein" },
        { name: 'fat', title: "Yağ" },
    ];

    const rows = () => {
        return foods.map((p, i) => ({
            name: p.name || '',
            group: p.group || '',
            unit: p.unit || '',
            measure: p.measure || '',
            calori: p.calori || "",
            crh: p.crh || "",
            protein: p.protein || "",
            fat: p.fat || "",
            action: <Tooltip title='Ekle'>
                <IconButton color='primary' onClick={() => {
                    const newFood = { ...p, foodId: p._id, foodunit: p.unit, foodcalori: p.calori, foodcrh: p.crh, foodprotein: p.protein, foodfat: p.fat }
                    if (isNew) {
                        handleAction([...meal, [newFood]])
                    } else {
                        handleAction([
                            ...meal.slice(0, foodIndex),
                            [...meal[foodIndex], newFood],
                            ...meal.slice(foodIndex + 1)
                        ])
                    }
                    handleClose()
                }} >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 250 },
        { columnName: 'group', width: 100 },
        { columnName: 'unit', width: 100 },
        { columnName: 'measure', width: 100 },
        { columnName: 'calori', width: 100 },
        { columnName: 'crh', width: 100 },
        { columnName: 'protein', width: 100 },
        { columnName: 'fat', width: 100 },
        { columnName: 'action', width: 100 }
    ];

    return (
        <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color='primary'>
                <Toolbar color='primary'>
                    <Typography variant="h6" className={classes.title}>
                        Besinler
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div style={{ marginTop: 50 }}>
                    <GridContainer justify='center'>
                        <GridItem xs={11} md={10}>
                            {foodTypes.map(m => {
                                return (
                                    <Button color={foodType === m ? 'success' : "info"} key={m} onClick={() => setFoodType(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                                )
                            })}
                            <DataGrid
                                rows={rows()}
                                columns={columns}
                                defaultColumnWidths={defaultColumnWidths}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FoodSelectDialog