import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button'
import { createMuscleGroup, updateMuscleGroup } from "_services/workout"
import { useAtom } from "jotai"
import { userAtom } from "_atoms/user"
import Swal from 'sweetalert2'

const styles = {

}

const useStyles = makeStyles(styles)

const MuscleGroup = props => {

    const [user] = useAtom(userAtom)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [mode, setMode] = useState(0)

    const [ id, setId ] = useState('')

    const { location } = props 

    useEffect(() => {
        if(location && location.group && location.group._id){
            const { group } = location
            setId(group._id)
            setName(group.name)
            setDescription(group.description)
            setMode(1)
        }
        return () => {
            setMode(0)
            setId('')
            setName('')
            setDescription('')
        }
    },[location])

    const { token } = user

    const classes = useStyles()

    const handleAddMuscleGroup = () => {
        const data = { name, description }
        if (name === '') {
            Swal.fire({
                icon: "warning",
                title: "Lütfen kas grubu adını giriniz."
            })
            return
        }
        createMuscleGroup(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Kas grubu oluşturuldu."
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleUpdateMuscleGroup = () => {
        const data = { id, name, description }
        if (name === '') {
            Swal.fire({
                icon: "warning",
                title: "Lütfen kas grubu adını giriniz."
            })
            return
        }
        updateMuscleGroup(token,data)
        .then( res => {
            if(res.data && res.data.code === 200){
                Swal.fire({
                    icon: "success",
                    title: "Kas grubu düzenlendi."
                })
            }else{
                Swal.fire({
                    icon: "error",
                    title: res.data.message
                })
            }
        })
        .catch( err => {
            Swal.fire({
                icon: "error",
                title: "Bir hata oluştu!"
            })
            console.log(err)
        })
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}> Kas Grubu {mode === 0 ? 'Ekle' : "Düzenle"}</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText='Kas Grubu Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: (e) => setName(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText='Kas Grubu Açıklaması'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: description,
                                        multiline: true,
                                        rows: 4,
                                        onChange: (e) => setDescription(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={10}>

                            </GridItem>
                            <GridItem xs={12} md={2}>
                                {mode === 0
                                    ?   <Button color='success' block onClick={handleAddMuscleGroup}>
                                        Ekle
                                        </Button>
                                    :   <Button color='success' block onClick={handleUpdateMuscleGroup}>
                                            Düzenle
                                        </Button>
                                }

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default MuscleGroup