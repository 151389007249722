import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { SortableElement } from "react-sortable-hoc";
import GridItem from 'components/Grid/GridItem'
import { Link } from 'react-router-dom'
import CardFooter from "components/Card/CardFooter";
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { Grid } from "@material-ui/core";

const DraggableCard = SortableElement((props) => {
  return (
    <GridItem xs={12} md={4}>
      <div>
        <Card>
          <CardHeader>
            <Grid container justify='flex-start'>
              <Grid item xs={8}>
                <CardIcon color='rose'>
                  <h5 style={{ color: "#fff" }}>{props.item.name}</h5>
                </CardIcon>
              </Grid>
              <Grid item xs={4}>
                <Grid container justify='flex-end'>
                  <Grid item>
                    <Link to={{ pathname: "/admin/paket-detay", subs: props.item }}>
                      <Tooltip title='Detay' align='right' >
                        <IconButton color='primary' >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <img src={props.item.bannerUrl} style={{ width: "100%", height: 200, objectFit: "contain" }} alt={props.item.name} />
          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
      </div>
    </GridItem>
  )
})

export default DraggableCard