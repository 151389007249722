import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getUserMeals, createUserMeal } from '_services/food'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput';

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FoodSelectDialog from 'views/Nutrition/FoodSelectDialog';
import { getMeals } from '_services/food';

const useStyles = makeStyles((theme) => ({
    ...styles,
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MealDialog = props => {
    const { open, toggle, meal, mealIndex, meals, setMeals, user, token, sablon } = props
    const [mealObject, setMealObject] = useState({})
    const [hour, setHour] = useState("")
    const [totalCalori, setTotalCalori] = useState("")
    const [foods, setFoods] = useState([])
    const [foodSelectDialog, setFoodSelectDialog] = useState(false)
    const [foodIndex, setFoodIndex] = useState(0)
    const [isNew, setIsNew] = useState(true)

    const classes = useStyles();

    const handleClose = props => {
        setMealObject({})
        setHour("")
        setTotalCalori("")
        setFoods([])
        setFoodIndex(0)
        setIsNew(true)
        toggle()
    }

    useEffect(() => {
        if (open && user !== '' && meal !== "" ) {
            let userMealType = meal
            if(meal === "Ara Öğün"){
                if(mealIndex === 1){
                    userMealType += "1"
                }
                if(mealIndex === 3){
                    userMealType += "2"
                }
                if(mealIndex === 5){
                    userMealType += "3"
                }
            }
            const data = { user, userMealType }
            getUserMeals(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setMealObject(res.data.data || {})
                        setHour(res.data.data.hour || "")
                        setTotalCalori(res.data.data.totalCalori || "")
                        setFoods(res.data.data.foods || [])
                        if( sablon[mealIndex] && sablon[mealIndex] !== ""){
                            const data = { _id : sablon[mealIndex]}
                            getMeals(data)
                            .then(res => {
                                if (res.data && res.data.code === 200) {
                                    let mealO = {
                                        foods : res.data.data[0]._id ? res.data.data[0].foods : [],
                                        totalCalori : res.data.data[0]._id ? res.data.data[0].foods : 0
                                    }
                                    setMealObject(mealO)
                                    setTotalCalori(res.data.data[0].totalCalori || "")
                                    setFoods(res.data.data[0].foods || [])
                                } else {
                                    return null
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        }
                    } else {
                        return null
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [open, user, meal, meals ])

    const handleFoodSelectToggle = () => {
        if (foodSelectDialog) {
            setFoodIndex(0)
            setIsNew(true)
        }
        setFoodSelectDialog(!foodSelectDialog)
    }

    const handleFoodSelect = (index, isNew) => {
        setFoodIndex(index)
        setIsNew(isNew)
        handleFoodSelectToggle()
    }

    const handleSubmit = () => {
        let userMealType = meal
        if(meal === "Ara Öğün"){
            if(mealIndex === 1){
                userMealType += "1"
            }
            if(mealIndex === 3){
                userMealType += "2"
            }
            if(mealIndex === 5){
                userMealType += "3"
            }
        }
        const data = { user, userMealType, foods, hour, totalCalori }
        createUserMeal(token, data)
        .then( res => {
            if(res.data && res.data.code === 200){
                setMeals([
                    ...meals.slice(0,mealIndex),
                    mealObject._id ? mealObject._id : res.data.data._id,
                    ...meals.slice(mealIndex + 1),
                ])
                handleClose()
            }
        })
        .catch( err => {
            console.log(err)
        })
    }

    const handleDefine = () => {
        let userMealType = meal
        if(meal === "Ara Öğün"){
            if(mealIndex === 1){
                userMealType += "1"
            }
            if(mealIndex === 3){
                userMealType += "2"
            }
            if(mealIndex === 5){
                userMealType += "3"
            }
        }
        const data = { user, userMealType, foods, hour, totalCalori }
        createUserMeal(token, data)
        .then( res => {
            if(res.data && res.data.code === 200){
                setMeals([
                    ...meals.slice(0,mealIndex),
                    mealObject._id ? mealObject._id : res.data.data._id,
                    ...meals.slice(mealIndex + 1),
                ])
            }
        })
        .catch( err => {
            console.log(err)
        })
    }

    return (
        <Dialog open={open} fullScreen onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color='primary'>
                <Toolbar color='primary'>
                    <Typography variant="h6" className={classes.title}>
                        {meal} Düzenle
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
            { !mealObject.foods 
                ?   <div style={{ marginTop: 50 }}>
                        <p>Bu kullanıcı için bu öğün tanımlanmamış</p>
                        <Button color='success' onClick={handleDefine}>Tanımla</Button>
                    </div>
                :   <div style={{ marginTop: 50 }}>
                    <GridContainer justify='center'>
                        <GridItem xs={11} md={10}>
                            <GridContainer alignItems="center">
                                <GridItem xs={12} md={3}>
                                    <CustomInput
                                        labelText='Ögün Tipi'
                                        disabled
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: meal,
                                            onChange: e => null
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} md={3}>
                                    <CustomInput
                                        labelText='Yaklaşık Toplam Kalori'
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: totalCalori,
                                            onChange: e => setTotalCalori(e.target.value),
                                            type: "number"
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} md={3}>
                                    <CustomInput
                                        labelText='Saat veya Zaman'
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: hour,
                                            onChange: e => setHour(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} md={3}>
                                    <Button color='success' style={{ float: "right" }} onClick={handleSubmit}> KAYDET </Button>
                                </GridItem>
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={12} md={8}>
                                            <h4 >Besinler</h4>
                                        </GridItem>
                                        <GridItem xs={12} md={4}>
                                            <Button color='rose' style={{ float: "right" }} onClick={handleFoodSelectToggle}> Besin Ekle </Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {foods.map((b, i) => {
                                    return (
                                        <GridItem xs={12} key={i}>
                                            <GridContainer>
                                                <GridItem xs={12} md={8}>
                                                    <h5> Besin {i + 1}</h5>
                                                </GridItem>
                                                <GridItem xs={12} md={4}>
                                                    <Button color='info' style={{ float: "right" }} onClick={() => handleFoodSelect(i, false)}> Alternatif Ekle </Button>
                                                </GridItem>
                                                {b.map((bb, ii) => {
                                                    return (
                                                        <GridItem xs={12} md={3} key={ii}>
                                                            <div style={{ border: "1px solid gray", borderRadius: 20, padding: 10, marginBottom: 20 }}>
                                                                <GridContainer>
                                                                    <GridItem xs={12} md={10}>
                                                                        <h4 >{bb.name} - {Math.ceil(bb.foodcalori)} Kkal</h4>
                                                                    </GridItem>
                                                                    <GridItem xs={12} md={2}>
                                                                        <Tooltip title='Çıkar' style={{ float: "right" }}>
                                                                            <IconButton onClick={() => {
                                                                                if (foods[i] && foods[i].length === 1) {
                                                                                    setFoods([
                                                                                        ...foods.slice(0, i),
                                                                                        ...foods.slice(i + 1)
                                                                                    ])
                                                                                } else {
                                                                                    setFoods([
                                                                                        ...foods.slice(0, i),
                                                                                        [
                                                                                            ...foods[i].slice(0, ii),
                                                                                            ...foods[i].slice(ii + 1)
                                                                                        ],
                                                                                        ...foods.slice(i + 1)
                                                                                    ])
                                                                                }
                                                                            }}>
                                                                                <DeleteForeverIcon color='secondary' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </GridItem>
                                                                </GridContainer>
                                                                <GridContainer>
                                                                    <GridItem xs={11}>
                                                                        <CustomInput
                                                                            labelText="Miktar"
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                            }}
                                                                            inputProps={{
                                                                                value: bb.foodunit,
                                                                                type: 'number',
                                                                                onChange: e => {
                                                                                    const { value } = e.target
                                                                                    const factor = value / bb.unit
                                                                                    setFoods([
                                                                                        ...foods.slice(0, i),
                                                                                        [
                                                                                            ...foods[i].slice(0, ii),
                                                                                            { ...foods[i][ii], foodunit: value, foodcalori: bb.calori * factor, foodcrh: bb.crh * factor, foodprotein: bb.protein * factor, foodfat: bb.fat * factor },
                                                                                            ...foods[i].slice(ii + 1)
                                                                                        ],
                                                                                        ...foods.slice(i + 1)
                                                                                    ])
                                                                                },
                                                                                endAdornment: (
                                                                                    <span>
                                                                                        {bb.measure}
                                                                                    </span>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={4}>
                                                                        <h5>KRH</h5>
                                                                        {Math.ceil(bb.foodcrh || 0)}
                                                                    </GridItem>
                                                                    <GridItem xs={4}>
                                                                        <h5>Protein</h5>
                                                                        {Math.ceil(bb.foodprotein || 0)}
                                                                    </GridItem>
                                                                    <GridItem xs={4}>
                                                                        <h5>Yağ</h5>
                                                                        {Math.ceil(bb.foodfat || 0)}
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </div>
                                                        </GridItem>
                                                    )
                                                })}
                                            </GridContainer>
                                        </GridItem>
                                    )
                                })}
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
            }
            </DialogContent>
            <FoodSelectDialog
                open={foodSelectDialog}
                toggle={handleFoodSelectToggle}
                handleAction={setFoods}
                foodIndex={foodIndex}
                meal={foods}
                isNew={isNew}
            />
        </Dialog>
    )
}

export default MealDialog