import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { auth } from './_services/user';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import { useAtom } from 'jotai';
import { userAtom } from './_atoms/user';

const hist = createBrowserHistory();

const App = () => {
  const [user, setUser] = useAtom(userAtom);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token !== '' && !user.loggedIn) {
      auth(token).then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ ...res.data.data, loggedIn: true });
        }
      });
    }
  }, [token]);


  return (
    <Router history={hist}>
      <Switch>
        <Route
          path='/auth'
          render={() => {
            return user.loggedIn ? (
              <Redirect to='/admin/dashboard' />
            ) : (
              <AuthLayout to='/login' />
            );
          }}
        />
        <Route
          path='/admin'
          render={() => {
            return user.loggedIn ? (
              <AdminLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Redirect
          from='/'
          to={user.loggedIn ? '/admin/dashboard' : '/auth/login'}
        />
      </Switch>
    </Router>
  );
};

export default App;