import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { getNutritions } from '_services/food'
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import { useAtom } from 'jotai';
import { nutritionAtom } from '_atoms/nutritionAtom';

const useStyles = makeStyles(styles)

const Nutritions = props => {
    const [nuts, setNuts] = useAtom(nutritionAtom)
    const [filter, setFilter] = useState("Tümü")
    const classes = useStyles()

    useEffect(() => {
        if(nuts.length === 0){
            getNutritions()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setNuts(res.data.data)
                }
            })
            .catch(err => console.log(err))
        }
    }, [])

    const filters = ["Tümü", "KİLO ALMA", "KİLO VERME", "HİPERTROFİ", "VEGAN", "VEJETERYAN", 'BK KİLO ALMA', 'BK KİLO VERME', 'BK HİPERTROFİ', 'BK VEGAN', 'BK VEJETERYAN' ]

    let filteredNuts = nuts

    if (filter !== 'Tümü') {
        filteredNuts = filteredNuts.filter(m => m.name.includes(filter))
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Beslenme Programları</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/beslenme-detay'>
                                    <Button color='success' style={{ float: "right" }}> Program Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                {filters.map(m => {
                    return (
                        <Button color={filter === m ? 'success' : "info"} key={m} onClick={() => setFilter(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                    )
                })}
            </GridItem>
            { filteredNuts.map(n => {
                return (
                    <GridItem xs={12} md={4} key={n._id}>
                        <Card style={{ height: 100 }}>
                            <CardHeader>
                                <GridContainer>
                                    <GridItem xs={12} md={8}>
                                        <h4 style={{ color: "#000", fontWeight: "500" }}>{n.name} - ({n.totalCalori}Kkal)</h4>
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <Link to={{ pathname: '/admin/beslenme-detay', nutrition: n }}>
                                            <IconButton style={{ float: "right" }}>
                                                <EditIcon color='primary' />
                                            </IconButton>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                        </Card>
                    </GridItem>
                )
            })}

        </GridContainer>
    )
}

export default Nutritions