import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getCustomerMeasures } from '_services/customer'

const Measurements = props => {
    const [measures, setMeasures] = useState([])
    const { id, token } = props

    useEffect(() => {
        if (id && id !== '') {
            const data = { id }
            getCustomerMeasures(token, data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setMeasures(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [id])

    return (
        <div>
            {measures.map(m => {
                return (
                    <div key={m._id} style={{ padding: 20, borderRadius: 8, border: "1px solid lightgray" }}>
                        <h3> Tarih : {moment(m.date).format("DD MMMM YYYY")}</h3>
                        <GridContainer>
                            <GridItem xs={3}>
                                <h5 style={{ marginLeft: "5%" }}></h5>
                                <GridContainer>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Kilo  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.weight ? (m.weight) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Kol  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.arm ? (m.arm) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Göğüs  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.chest ? (m.chest) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Omuz  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.chest ? (m.chest) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Bilek  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.wrist ? (m.wrist) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Bel  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.stomach ? (m.stomach) : "Girilmemiş"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Kalça  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {m.hips ? (m.hips) : "Girilmemiş"}</span>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={3}>
                                <h5 style={{ marginLeft: "5%" }}>Önden Fotoğraf</h5>
                                {m.frontImg &&
                                    <img src={m.frontImg} alt='img' style={{ width: "90%", marginLeft: "5%", objectFit: "contain" }} />
                                }
                            </GridItem>
                            <GridItem xs={3}>
                                <h5 style={{ marginLeft: "5%" }}>Yandan Fotoğraf</h5>
                                {m.sideImg &&
                                    <img src={m.sideImg} alt='img' style={{ width: "90%", marginLeft: "5%", objectFit: "contain" }} />
                                }
                            </GridItem>
                            <GridItem xs={3}>
                                <h5 style={{ marginLeft: "5%" }}>Arkadan Fotoğraf</h5>
                                {m.backImg &&
                                    <img src={m.backImg} alt='img' style={{ width: "90%", marginLeft: "5%", objectFit: "contain" }} />
                                }
                            </GridItem>
                        </GridContainer>
                    </div>
                )
            })}
        </div>
    )
}

export default Measurements