import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState, useEffect } from 'react'
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { createNutrition } from '_services/food';
import Swal from 'sweetalert2';
import { updateNutrition } from '_services/food';
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import { Select as Select2 } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import { getMeals } from '_services/food';

const useStyles = makeStyles(styles)


const Nutrition = props => {
    const [user] = useAtom(userAtom)
    const [name, setName] = useState("")
    const [totalCalori, setTotalCalori] = useState("")
    const [meals, setMeals] = useState([])
    const [mealPattern, setMealPattern] = useState([])
    const [mealPatternString, setMealPatternString] = useState('')

    const [mode, setMode] = useState(0)
    const [id, setId] = useState("")

    const { token } = user

    const { location } = props
    const classes = useStyles()

    const [ms, setMs] = useState([])

    useEffect(() => {
        getMeals()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setMs(res.data.data.map(m => ({ value: m._id, label: (m.name + ' - ' + m.totalCalori + ' Kkal'), group : m.group, mealType : m.mealType  })))
                }
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (mealPatternString === "") {
            setMealPattern([])
        } else {
            const pat = JSON.parse(mealPatternString)
            if (pat.pattern) {
                setMealPattern(pat.pattern)
            }
        }
    }, [mealPatternString])

    const valueResolver = value => {
        const index = ms.map( m => m.value).indexOf(value)
        let v = ms[index]
        return v
    }

    useEffect(() => {
        if (location && location.nutrition && location.nutrition._id) {
            const { nutrition } = location
            setMode(1)
            setId(nutrition._id || '')
            setName(nutrition.name || '')
            setTotalCalori(nutrition.totalCalori || '')
            setMeals(nutrition.meals || [])
            setMealPattern(nutrition.mealPattern || [])
            setMealPatternString(nutrition.mealPattern && nutrition.mealPattern.length > 0 ? JSON.stringify({ pattern : nutrition.mealPattern }) : '')
        }
        return (() => {
            setMode(0)
            setId('')
            setName('')
            setTotalCalori("")
            setMeals([])
            setMealPattern([])
        })
    }, [location])

    const handleSubmit = () => {
        const data = { name, totalCalori, meals, mealPattern }
        let ok = true 
        let index = 0
        for( let i = 0; i < meals.length; i++){
            if(meals[i] === ''){
                ok = false
                index = i
            }
        }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Lütfen program ismini giriniz."
            })
            return
        }
        if(!ok){
            Swal.fire({
                icon: "warning",
                title: "Lütfen " + mealPattern[index] + " seçiniz."
            })
            return 
        }
        createNutrition(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Beslenme Programı Tanımlandı"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu!"
                })
            })
    }

    const handleEdit = () => {
        const data = { id, name, totalCalori, meals, mealPattern }
        let ok = true 
        let index = 0
        for( let i = 0; i < meals.length; i++){
            if(meals[i] === ''){
                ok = false
                index = i
            }
        }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Lütfen program ismini giriniz."
            })
            return
        }
        if(!ok){
            Swal.fire({
                icon: "warning",
                title: "Lütfen " + mealPattern[index] + " seçiniz."
            })
            return 
        }
        updateNutrition(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Beslenme Programı Düzenlendi"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu!"
                })
            })
    }

    const groups = ["Kilo Alma", "Kilo Verme", "Hipertrofi", "Vegan", "Vejeteryan", "Suplement", "Kuruyemiş"]

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Beslenme Programı</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={mode === 0 ? handleSubmit : handleEdit}> {mode === 0 ? "Ekle" : "Düzenle"} </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer spacing={2} alignItems='center'>
                            <GridItem xs={12} md={4}>
                                <CustomInput
                                    labelText='Program Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: e => setName(e.target.value)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <CustomInput
                                    labelText='Toplam Yaklaşık Kalori'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: totalCalori,
                                        onChange: e => setTotalCalori(e.target.value),
                                        type: 'number'
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="multiple-select"
                                        className={classes.selectLabel}
                                    >
                                        Öğün Sayısı
                                    </InputLabel>
                                    <Select2
                                        value={mealPatternString}
                                        onChange={e => {
                                            setMealPatternString(e.target.value)
                                            const pat = JSON.parse(e.target.value)
                                            if (pat.pattern) {
                                                let arr = Array.from({ length: pat.pattern.length }, (v,) => "")
                                                setMeals(arr)
                                            }
                                        }}
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: classes.select }}
                                        inputProps={{
                                            name: "multipleSelect",
                                            id: "multiple-select"
                                        }}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <MenuItem
                                            value=''
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seçiniz
                                        </MenuItem>
                                        <MenuItem
                                            value={JSON.stringify({ pattern: ["Kahvaltı", "Öğle Yemeği", "Akşam Yemeği"] })}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            3 ( Kahvaltı - Öğlen - Akşam )
                                        </MenuItem>
                                        <MenuItem
                                            value={JSON.stringify({ pattern: ["Kahvaltı", "Öğle Yemeği", 'Ara Öğün', "Akşam Yemeği"] })}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            4 ( Kahvaltı - Öğlen - Ara - Akşam )
                                        </MenuItem>
                                        <MenuItem
                                            value={JSON.stringify({ pattern: ["Kahvaltı", 'Ara Öğün', "Öğle Yemeği", 'Ara Öğün', "Akşam Yemeği"] })}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            5 ( Kahvaltı - Ara - Öğlen - Ara - Akşam )
                                        </MenuItem>
                                        <MenuItem
                                            value={JSON.stringify({ pattern: ["Kahvaltı", 'Ara Öğün', "Öğle Yemeği", 'Ara Öğün', "Akşam Yemeği", 'Ara Öğün'] })}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            6 ( Kahvaltı - Ara - Öğlen - Ara - Akşam - Ara )
                                        </MenuItem>
                                    </Select2>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12}> <h4>Öğünler</h4></GridItem>
                            {mealPattern.length > 0 && mealPattern.map((meal, i) => {
                                const filtered = ms.filter( a => a.mealType === meal)
                                let groupTitles = []
                                for( let i = 0; i < groups.length; i++){
                                    const opt = {
                                        label : groups[i],
                                        options : filtered.filter( a => a.group === groups[i] )
                                    }
                                    groupTitles = [
                                        ...groupTitles,
                                        opt
                                    ]
                                }
                                return (
                                    <GridItem xs={12} md={4} key={meal + i}>
                                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                                            <Select options={[{ value: "", label: meal }, ...groupTitles]} value = {valueResolver(meals[i])} placeholder={meal} onChange={e => {
                                                setMeals([
                                                    ...meals.slice(0, i),
                                                    e.value,
                                                    ...meals.slice(i + 1)
                                                ])
                                            }}
                                            noOptionsMessage = {() => <div> Seçenek Bulunamadı </div>}
                                            />
                                        </div>
                                    </GridItem>
                                )
                            })}
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Nutrition