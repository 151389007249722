import React, { useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from 'components/Grid/GridItem';
import DataGrid from 'components/DataGrid/SimpleGrid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import { useAtom } from 'jotai';
import { getNutritions } from '_services/food';
import { nutritionAtom } from '_atoms/nutritionAtom';
import CheckIcon from '@material-ui/icons/Check';

const ProgramDialog = props => {
    const { open, toggle, setMealPattern, setSablon, setMealPatternString, setName, setTotalCalori } = props
    const [ nuts, setNuts ] = useAtom(nutritionAtom)
    const [filter, setFilter] = useState("Tümü")

    useEffect(() => {
        if(open){
            if(nuts.length === 0){
                getNutritions()
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setNuts(res.data.data)
                    }
                })
                .catch(err => console.log(err))
            }
        }
    },[open])

    const handleClose = () => {
        toggle()
    }

    const filters = ["Tümü", "KİLO ALMA", "KİLO VERME", "HİPERTROFİ", "VEGAN", "VEJETERYAN", 'BK KİLO ALMA', 'BK KİLO VERME', 'BK HİPERTROFİ', 'BK VEGAN', 'BK VEJETERYAN' ]

    let filteredNuts = nuts

    if (filter !== 'Tümü') {
        filteredNuts = filteredNuts.filter(m => m.name.includes(filter))
    }

    const columns = [
        { name: 'name', title: "Program Adı" },
        { name: 'action', title: "Seç" }
    ];

    const handleChoose = (p) => {
        setMealPattern(p.mealPattern)
        setSablon(p.meals)
        setMealPatternString(JSON.stringify({ pattern: p.mealPattern }))
        setName(p.name)
        setTotalCalori(p.totalCalori)
        handleClose()
    }

    const rows = () => {
        return filteredNuts.map((p) => ({
            name: (p.name + " - " + p.totalCalori + " Kkal") || "",
            action: <Tooltip title='Seç'>
                    <IconButton color='primary' onClick={() => handleChoose(p)} >
                        <CheckIcon />
                    </IconButton>
                </Tooltip>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 800 },
        { columnName: 'action', width: 80 }
    ];

    return(
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
            <DialogTitle>Program Seç</DialogTitle>
            <DialogContent>
                <Grid container>
                    <GridItem xs={12}>
                        {filters.map(m => {
                            return (
                                <Button color={filter === m ? 'success' : "info"} key={m} onClick={() => setFilter(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                            )
                        })}
                    </GridItem>
                    <Grid item xs={12}>
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ProgramDialog