import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { login } from '../../_services/user';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
// import ForgotPasswordDialog from './ForgotPasswordDialog';

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [username, setUserName] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [password, setPassword] = useState('');
  const [, setUser] = useAtom(userAtom);
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [forgotPassDialog, setForgotPassDialog] = useState(false);
  
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  
  const classes = useStyles();
  const handleSubmit = () => {
    const data = { username, password };
    setLoading(true);
    login(data)
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ ...res.data.data, loggedIn: true, token : res.data.token });
          localStorage.setItem('token', res.data.token);
        } else {
          setAlert(res.data.message);
        }
      })
      .catch((err) => setLoading(false));
  };

  const handleForgotPasswordDialog = () => {
    setForgotPassDialog(!forgotPassDialog);
  };

  return (
    <div className={classes.container}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color='rose'
              >
                <h4 className={classes.cardTitle}>Giriş Yapın</h4>
                <p></p>
              </CardHeader>
              <CardBody>
                {alert !== '' && (
                  <SnackbarContent color='danger' message={alert} />
                )}
                <CustomInput
                  labelText='Kullanıcı Adı'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: username,
                    onChange: (e) => setUserName(e.target.value),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText='Şifre'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                    onKeyDown : (e) => {
                      let { keyCode } = e
                      if( keyCode === 13 ){
                        handleSubmit()
                      }
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {loading ? (
                  <CircularProgress color='secondary' />
                ) : (
                  <div>
                    <Button
                      color='rose'
                      simple
                      size='lg'
                      block
                      onClick={handleSubmit}
                    >
                      GİRİŞ YAP
                    </Button>
                    {/* <Button
                      color='warning'
                      simple
                      size='lg'
                      block
                      onClick={handleForgotPasswordDialog}
                    >
                      ŞİFREMİ UNUTTUM
                    </Button> */}
                  </div>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      {/* <ForgotPasswordDialog
        open={forgotPassDialog}
        toggle={handleForgotPasswordDialog}
      /> */}
    </div>
  );
}