export const measures = [
    "adet",
    "gram",
    "ml",
    "porsiyon",
    "fincan",
    "şişe",
    "kupa",
    "paket",
    "kase",
    "küçük boy",
    "orta boy",
    "büyük boy",
    "dilim",
    "yemek kaşığı",
    "çay kaşığı",
    "çay bardağı",
    "kibrit kutusu",
    "tek",
    "duble",
    "bardak",
]

export const foodTypes = [
    "etler",
    "ekmekler",
    "baharatlar",
    "çorbalar",
    "sular",
    "kahveler",
    "çaylar",
    "baklagiller",
    "süt ürünleri",
    "yağlar",
    "sakatatlar",
    "kahvaltılıklar",
    "atıştırmalıklar",
    "meyveler",
    "sebzeler",
    "meyve suları",
    "turşular",
    "suplementler",
    "fast food",
    "alkoller",
]