import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { IconButton, Tooltip } from "@material-ui/core";
import { getMuscleGroups } from "_services/workout";
import { updateMuscleGroup } from "_services/workout";
import { useAtom } from "jotai";
import { userAtom } from "_atoms/user";


const useStyles = makeStyles(styles)

const MuscleGroups = props => {
    const [user] = useAtom(userAtom)
    const [groups, setGroups] = useState([])
    const { token } = user
    const classes = useStyles()

    useEffect(() => {
        const data = { isActive: true }
        getMuscleGroups(data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setGroups(res.data.data)
                }
                else {
                    console.log(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleDelete = (id, index) => {
        const data = { id, isActive: false }
        updateMuscleGroup(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setGroups([
                        ...groups.slice(0, index),
                        ...groups.slice(index + 1)
                    ])
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12} md={10}>

            </GridItem>
            <GridItem xs={12} md={2}>
                <Link to={{ pathname: "/admin/kas-grubu" }} >
                    <Button color='success' block>
                        Ekle
                    </Button>
                </Link>
            </GridItem>
            { groups.map((group, index) => {
                return (
                    <GridItem xs={12} md={3} key={group._id}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}> {group.name} </h4>
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                <p><strong>Açıklama : </strong>{group.description ? group.description : " - "}</p>
                            </CardBody>
                            <CardFooter>
                                <GridContainer justify='flex-end'>
                                    <GridItem xs={4}>
                                        <Tooltip title='Sil'>
                                            <IconButton onClick={() => handleDelete(group._id, index)}>
                                                <HighlightOffIcon color='primary' style={{ fontSize: 20 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Link to={{ pathname: "/admin/kas-grubu", group: group }} >
                                            <Tooltip title='Düzenle'>
                                                <IconButton>
                                                    <EditIcon color='primary' style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>

                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}

        </GridContainer>
    )
}

export default MuscleGroups