
import GridContainer from 'components/Grid/GridContainer'
import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import DraggableCard from './DraggableCard'

const SortableGrid = SortableContainer(({ items }) => {
    return (
        <GridContainer>
            {items.map((item, index) => {
                return (
                    <DraggableCard
                        index={index}
                        item={item}
                        key={item._id}
                    />
                )
            })}
        </GridContainer>
    )
})

export default SortableGrid