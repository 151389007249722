import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton, Tooltip } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import FoodSelectDialog from './FoodSelectDialog';
import Swal from 'sweetalert2';
import { createMeal, updateMeal } from '_services/food'

const useStyles = makeStyles(styles)

const Meal = props => {
    const [user] = useAtom(userAtom)
    const [name, setName] = useState("")
    const [mealType, setMealType] = useState("")
    const [totalCalori, setTotalCalori] = useState("")
    const [foods, setFoods] = useState([])
    const [foodSelectDialog, setFoodSelectDialog] = useState(false)
    const [group, setGroup] = useState("")
    const [foodIndex, setFoodIndex] = useState(0)
    const [isNew, setIsNew] = useState(true)
    const [mode, setMode] = useState(0)
    const [id, setId] = useState('')
    const { token } = user
    const classes = useStyles()

    const { location } = props

    useEffect(() => {
        if (location && location.meal && location.meal._id) {
            const { meal } = location
            setMode(1)
            setId(meal._id || '')
            setName(meal.name || '')
            setTotalCalori(meal.totalCalori || '')
            setFoods(meal.foods || [])
            setMealType(meal.mealType || '')
            setGroup(meal.group || '')
        }
        return (() => {
            setMode(0)
            setId('')
            setName('')
            setTotalCalori("")
            setFoods([])
            setMealType("")
            setGroup("")
        })
    }, [location])

    const handleFoodSelectToggle = () => {
        if (foodSelectDialog) {
            setFoodIndex(0)
            setIsNew(true)
        }
        setFoodSelectDialog(!foodSelectDialog)
    }

    const handleFoodSelect = (index, isNew) => {
        setFoodIndex(index)
        setIsNew(isNew)
        handleFoodSelectToggle()
    }

    const handleSubmit = () => {
        const data = { name, totalCalori, foods, mealType, group }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Lütfen Öğün ismini giriniz."
            })
            return
        }
        createMeal(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Öğün Tanımlandı"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu!"
                })
            })
    }

    const handleEdit = () => {
        const data = { id, name, totalCalori, foods, mealType, group }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Lütfen Öğün ismini giriniz."
            })
            return
        }
        updateMeal(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Öğün Tanımlandı"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu!"
                })
            })
    }

    const groups = ["Kilo Alma", "Kilo Verme", "Hipertrofi", "Vegan", "Vejeteryan", "Suplement", "Kuruyemiş"]

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Öğün Ekle</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={mode === 0 ? handleSubmit : handleEdit}> {mode === 0 ? "Ekle" : "Düzenle"} </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer alignItems="center">
                            <GridItem xs={12} md={4}>
                                <CustomInput
                                    labelText='Ögün Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: e => setName(e.target.value)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <CustomInput
                                    labelText='Yaklaşık Toplam Kalori'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: totalCalori,
                                        onChange: e => setTotalCalori(e.target.value),
                                        type: "number"
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="multiple-select"
                                        className={classes.selectLabel}
                                    >
                                        Grubu
                                    </InputLabel>
                                    <Select
                                        value={mealType}
                                        onChange={e => setMealType(e.target.value)}
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: classes.select }}
                                        inputProps={{
                                            name: "multipleSelect",
                                            id: "multiple-select"
                                        }}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <MenuItem
                                            value=''
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seçiniz
                                        </MenuItem>

                                        <MenuItem
                                            value="Kahvaltı"
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Kahvaltı
                                        </MenuItem>
                                        <MenuItem
                                            value="Öğle Yemeği"
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Öğle Yemeği
                                        </MenuItem>
                                        <MenuItem
                                            value="Akşam Yemeği"
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Akşam Yemeği
                                        </MenuItem>
                                        <MenuItem
                                            value="Ara Öğün"
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Ara Öğün
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="multiple-select"
                                        className={classes.selectLabel}
                                    >
                                        Beslenme Tipi
                                    </InputLabel>
                                    <Select
                                        value={group}
                                        onChange={e => setGroup(e.target.value)}
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: classes.select }}
                                        inputProps={{
                                            name: "multipleSelect",
                                            id: "multiple-select"
                                        }}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <MenuItem
                                            value=''
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seçiniz
                                        </MenuItem>
                                        {groups.map(g => {
                                            return (
                                                <MenuItem
                                                    key = {g}
                                                    value={g}
                                                    classes={{
                                                        root: classes.selectMenuItem
                                                    }}
                                                >
                                                    {g}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Besinler</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={handleFoodSelectToggle}> Besin Ekle </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            {foods.map((b, i) => {
                return (
                    <GridItem xs={12} key={i}>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <h5> Besin {i + 1}</h5>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='info' style={{ float: "right" }} onClick={() => handleFoodSelect(i, false)}> Alternatif Ekle </Button>
                            </GridItem>
                            {b.map((bb, ii) => {
                                return (
                                    <GridItem xs={12} md={3} key={ii}>
                                        <Card>
                                            <CardHeader>
                                                <GridContainer>
                                                    <GridItem xs={12} md={10}>
                                                        <CardIcon color='success'>
                                                            <h4 style={{ color: "#fff" }}>{bb.name} - {Math.ceil(bb.foodcalori)} Kkal</h4>
                                                        </CardIcon>
                                                    </GridItem>
                                                    <GridItem xs={12} md={2}>
                                                        <Tooltip title='Çıkar' style={{ float: "right" }}>
                                                            <IconButton onClick={() => {
                                                                if (foods[i] && foods[i].length === 1) {
                                                                    setFoods([
                                                                        ...foods.slice(0, i),
                                                                        ...foods.slice(i + 1)
                                                                    ])
                                                                } else {
                                                                    setFoods([
                                                                        ...foods.slice(0, i),
                                                                        [
                                                                            ...foods[i].slice(0, ii),
                                                                            ...foods[i].slice(ii + 1)
                                                                        ],
                                                                        ...foods.slice(i + 1)
                                                                    ])
                                                                }
                                                            }}>
                                                                <DeleteForeverIcon color='secondary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={11}>
                                                        <CustomInput
                                                            labelText="Miktar"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                value: bb.foodunit,
                                                                type: 'number',
                                                                onChange: e => {
                                                                    const { value } = e.target
                                                                    const factor = value / bb.unit
                                                                    setFoods([
                                                                        ...foods.slice(0, i),
                                                                        [
                                                                            ...foods[i].slice(0, ii),
                                                                            { ...foods[i][ii], foodunit: value, foodcalori: bb.calori * factor, foodcrh: bb.crh * factor, foodprotein: bb.protein * factor, foodfat: bb.fat * factor },
                                                                            ...foods[i].slice(ii + 1)
                                                                        ],
                                                                        ...foods.slice(i + 1)
                                                                    ])
                                                                },
                                                                endAdornment: (
                                                                    <span>
                                                                        {bb.measure}
                                                                    </span>
                                                                ),
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={4}>
                                                        <h5>KRH</h5>
                                                        {Math.ceil(bb.foodcrh || 0)}
                                                    </GridItem>
                                                    <GridItem xs={4}>
                                                        <h5>Protein</h5>
                                                        {Math.ceil(bb.foodprotein || 0)}
                                                    </GridItem>
                                                    <GridItem xs={4}>
                                                        <h5>Yağ</h5>
                                                        {Math.ceil(bb.foodfat || 0)}
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                )
                            })}
                        </GridContainer>
                    </GridItem>
                )
            })}
            <FoodSelectDialog
                open={foodSelectDialog}
                toggle={handleFoodSelectToggle}
                handleAction={setFoods}
                foodIndex={foodIndex}
                meal={foods}
                isNew={isNew}
            />
        </GridContainer>
    )
}

export default Meal